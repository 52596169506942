import { useForm } from "react-hook-form";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";

import { kgData } from "../../../constants/data/kgData";
import {
  IPostInputs,
  SubmitHandlerCreatePost,
  countryCode,
} from "../../../constants/types/types";
import { auth, db, storage } from "../../../firebase";
import useUserData from "../../../constants/getUser/getUser";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { v4 } from "uuid";
import { async } from "q";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faCross,
  faImage,
  faPlus,
  faPlusCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

export const CreatePost = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      fromCity: "",
      toCity: "",
      weight: "",
      weightUnit: "",
      describeCargo: "",
      phoneNumber: "",
      views: "",
    },
  });

  const { userData } = useUserData();
  const [disable, setDisable] = useState(false);

  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [isUploaded, setIsUploaded] = useState({ message: "", color: "" });
  const [overlayVisible, setOverlayVisible] = useState(false);

  useEffect(() => {
    if (userData && userData.phoneNumber) {
      setValue("phoneNumber", userData?.phoneNumber);
    }
  }, [userData, setValue]);

  const uploadFiles = async () => {
    if (imageFiles === null) return [];
    let urls: string[] = [];
    for (let i = 0; i < imageFiles.length; i++) {
      const imageRef = ref(storage, `images/${imageFiles[i].name + v4()}`);
      await uploadBytes(imageRef, imageFiles[i])
        .then(() => {
          console.log("Upload success");

          // Get download URL
          getDownloadURL(imageRef)
            .then((url) => {
              console.log("File available at", url);
              urls.push(url);
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log("Error", error));
    }
    return urls; // Return URLs
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const urls: string[] = [...imageUrls]; // Copy the existing imageUrls

      // Append new files to imageFiles
      const newImageFiles = imageFiles ? [...imageFiles, ...files] : [...files];
      setImageFiles(newImageFiles);

      // Create a promise for each file read operation
      const fileReadPromises = files.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            urls.push(reader.result as string);
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      // Use Promise.all to wait for all promises to resolve
      Promise.all(fileReadPromises).then(() => {
        setImageUrls(urls);
      });
    }
  };

  const handleDelete = (index: number) => {
    setImageUrls((prevState) => prevState.filter((_, i) => i !== index));
    setImageFiles((prevState) => prevState.filter((_, i) => i !== index));
  };

  const onSubmit: SubmitHandlerCreatePost = async (formState) => {
    try {
      setDisable(true);
      const user = auth.currentUser;
      if (!user) {
        throw new Error("User is not authenticated");
      }
      await setOverlayVisible(!overlayVisible)

      // Upload files and get download URLs
      const urls = await uploadFiles();

      const userUid = user.uid;
      const displayName = user.displayName;
      const time = serverTimestamp();

      let formDataWithUID: { [key: string]: any } = {
        ...formState,
        userUid,
        displayName,
        time,
        docId: "",
        imageURLs: urls, // Use returned URLs
      };
      const docRef = await addDoc(collection(db, "posts"), formDataWithUID);
      const docId = docRef.id;
      formDataWithUID.docId = docId;
      await setDoc(doc(db, "posts", docId), formDataWithUID);
      setIsUploaded({
        message: "Пост успешно загружен!",
        color: "text-green-600",
      });
      await window.location.reload();
    } catch (error) {
      console.error("Error adding document: ", error);
      setIsUploaded({
        message: "Вышла ошибка. Повторите процессю",
        color: "text-red-600",
      });
    }
  };

  return (
    <div
      className="
      md:bg-zinc-100/30 md:backdrop-blur-[20px] 
      md:shadow-gray-800 md:shadow-2xl
      md:border md:rounded-3xl 
      w-full md:max-w-[700px]
      m-auto py-10 px-5 my-10
      "
    >
      <h1 className="mb-5 text-center">Подача Объявления</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-10">
          <div className="grid sm:grid-cols-2 xs:grid-cols-1 gap-10">
            <div className="">
              <span>От: </span>
              <select
                id="fromCity"
                placeholder="От"
                {...register("fromCity", { required: true })}
                className={`block hover:border-blue-400 active:border-black
                outline-none border-black border-b bg-inherit
                text-sm w-full p-1.5`}
              >
                <option value={""} disabled className="">
                  От
                </option>
                {kgData.map((k, i) => (
                  <option key={i} value={k.label} className=" rounded-none">
                    {k.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <span>До: </span>
              <select
                placeholder="До"
                {...register("toCity", { required: true })}
                className={`block hover:border-blue-400  outline-none
                border-black  border-b bg-inherit
                text-sm w-full p-1.5`}
              >
                <option value={""} disabled className="">
                  До
                </option>

                {kgData.map((k, i) => (
                  <option key={i} value={k.label} className="">
                    {k.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid sm:grid-cols-2 xs:grid-cols-1 gap-10">
            <div>
              <span>Вес груза: </span>
              <input
                {...register("weight", { required: true })}
                placeholder="сколько"
                type="number"
                className={`block hover:border-blue-400  outline-none 
                border-black  border-b bg-inherit
                text-sm w-full p-1.5`}
              />
            </div>
            <div>
              <span>Единица измерения</span>
              <select
                {...register("weightUnit", { required: true })}
                className={`block hover:border-blue-400  outline-none 
                border-black  border-b bg-inherit
                text-sm w-full p-1.5`}
                placeholder="тонна, килограмм или грамм"
              >
                <option disabled defaultValue={""}>
                  тонна или килограмм
                </option>
                <option value={"тонна"} className="">
                  тонна
                </option>
                <option value={"килограмм"} className="">
                  килограмм
                </option>
              </select>
            </div>
          </div>
          <div>
            <span>Дайте краткое описание груза:</span>
            <textarea
              {...register("describeCargo", { required: true })}
              className={`block hover:border-blue-400 
                outline-none
              } 
             border-black  border-b bg-inherit
              text-sm w-full p-1.5`}
            />
          </div>
          <div className="grid sm:grid-cols-2 xs:grid-cols-1 gap-5">
            <div>
              <span>Номер телефона</span>
              <div className="flex">
                <input
                  type="text"
                  value={countryCode}
                  disabled
                  className={`block hover:border-blue-400  
                  outline-none max-w-[48px]
                border-black  border-b bg-inherit
                  text-sm w-full p-1.5`}
                />
                <input
                  maxLength={9}
                  defaultValue={userData?.phoneNumber}
                  {...register("phoneNumber", { required: true })}
                  className={`block hover:border-blue-400  
                  outline-none
                border-black  border-b bg-inherit
                  text-sm w-full py-1.5`}
                />
              </div>
            </div>
          </div>
          {/* multiple files upload */}
          <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 xs:bg-white">
            <div className="text-center">
              <FontAwesomeIcon
                icon={faImage}
                className="mx-auto h-12 w-12 text-blue-600"
                aria-hidden="true"
              />
              <div className="flex w-full m-auto justify-center text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative p-1 mt-1 cursor-pointer rounded border border-black bg-white font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>Выберите фото</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    accept="image/*"
                    multiple
                    onChange={handleImageChange}
                  />
                </label>
              </div>
              <div className="text-xs leading-5 text-gray-600 mt-2 w-full">
                {imageUrls ? (
                  <div className="grid grid-cols-4 xs:grid-cols-2 gap-4 w-full p-2">
                    {imageUrls.map((image, index) => (
                      <div
                        key={index}
                        className=" rounded shadow-lg shadow-gray-700 relative"
                      >
                        <img
                          src={image}
                          className="object-fill w-40 h-40 m-auto rounded"
                        />
                        <button
                          onClick={() => handleDelete(index)}
                          className="absolute top-2 right-2 z-10 text-3xl"
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <span>Можете выбрать несколько фото</span>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-2">
            <button
              disabled={disable}
              type="submit"
              className={`${
                disable
                  ? "bg-gray-500 cursor-default"
                  : "bg-blue-500  hover:bg-blue-700 active:bg-blue-500"
              } p-2  w-full rounded-[5px] text-white`}
            >
              Отправить
            </button>
            <div>
              {isUploaded.message.length > 1 ? (
                <span className={`${isUploaded.color}`}>
                  {isUploaded.message}
                </span>
              ) : (
                <span>Подождите завершения загрузки</span>
              )}
            </div>
          </div>
        </div>
      </form>
      {overlayVisible && (
        <div className="fixed inset-0 bg-gray-100 backdrop-blur-[30px] opacity-70 z-[70]"></div>
      )}
    </div>
  );
};
