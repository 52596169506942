import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import "./App.css";

import { Navbar } from "./components/navbar/navbar";
import { ForgotPassword } from "./pages/auth/forgotPassword/forgotPassword";
import { NewPassword } from "./pages/auth/newPassword/newPassword";
import { SignIn } from "./pages/auth/signIn/signIn";
import { SignUp } from "./pages/auth/signUp/signUp";
import { CreatePost } from "./pages/crud-post/create-post/createPost";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./context/AuthContex";
import { MyPosts } from "./pages/myPosts/myPosts";
import Footer from "./components/footer/footer";
import { UserPage } from "./pages/userPage/userPage";
import { Background } from "./components/background/background";
import { MobileNavbar } from "./components/navbar/mobileNavbar";

import { Provider } from "react-redux";
import { store } from "./store/store";

import { UserMenu } from "./pages/userPage/userMenu";
import { PostPage } from "./pages/userPage/userPostPage";
import { SignUpWithEmail } from "./pages/auth/signUp/signUpWithEmail";
import { SignInWithEmail } from "./pages/auth/signIn/signInWithEmail";
import { CheckEmail } from "./pages/auth/forgotPassword/checkEmail";
import { SendPasswordLinkToEmail } from "./pages/auth/forgotPassword/forgotPasswordEmail";
import { NewPasswordEmail } from "./pages/auth/newPassword/newPasswordEmail";

import {App as CapacitorApp} from '@capacitor/app'

const LazyHome = React.lazy(() => import("./pages/home/home"));

type RequireAuthProps = {
  children: React.ReactNode;
};

function App(): JSX.Element {
  // const {darkMode} = useContext(DarkModeContext)
  const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
    return currentUser ? <>{children}</> : <Navigate to="/phone-sign-in" />;
  };

  const AlreadySignedIn: React.FC<RequireAuthProps> = ({ children }) => {
    const { currentUser } = useContext(AuthContext);
    return currentUser ? <Navigate to="/" /> : <>{children}</>;
  };

  const { currentUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      if (canGoBack) {
        window.history.back();
      } else {
        CapacitorApp.exitApp();
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <div>
        <Background className="bg-gray-200" />
        {loading ? (
          <div className="test flex justify-center h-full">
            <div className="lds-hourglass "></div>
          </div>
        ) : (
          <Router>
            <div
              className="flex flex-col min-h-screen"
              // style={{ height: "100vh" }}
            >
              <Navbar />

              <div className="grow">
                <Routes>
                  <Route
                    path="/phone-sign-up"
                    element={
                      <AlreadySignedIn>
                        <SignUp />
                      </AlreadySignedIn>
                    }
                  />

                  <Route
                    path="/email-sign-up"
                    element={
                      <AlreadySignedIn>
                        <SignUpWithEmail />
                      </AlreadySignedIn>
                    }
                  />
                  <Route
                    path="/phone-sign-in"
                    element={
                      <AlreadySignedIn>
                        <SignIn />
                      </AlreadySignedIn>
                    }
                  />
                  <Route
                    path="/email-sign-in"
                    element={
                      <AlreadySignedIn>
                        <SignInWithEmail />
                      </AlreadySignedIn>
                    }
                  />
                  <Route
                    path="/check-email"
                    element={
                      <AlreadySignedIn>
                        <CheckEmail />
                      </AlreadySignedIn>
                    }
                  />
                  <Route
                    path="/send-link"
                    element={
                      <AlreadySignedIn>
                        <SendPasswordLinkToEmail />
                      </AlreadySignedIn>
                    }
                  />

                  <Route
                    path="/new-password"
                    element={
                      <RequireAuth>
                        <NewPassword />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/reset-password"
                    element={
                      <AlreadySignedIn>
                        <NewPasswordEmail />
                      </AlreadySignedIn>
                    }
                  />
                  <Route
                    path="/forgot-password"
                    element={
                      <AlreadySignedIn>
                        <ForgotPassword />
                      </AlreadySignedIn>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <RequireAuth>
                        <React.Suspense fallback="loading...">
                          <LazyHome />
                        </React.Suspense>
                      </RequireAuth>
                    }
                  />

                  <Route
                    path="/create-post"
                    element={
                      <RequireAuth>
                        <CreatePost />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="user-menu"
                    element={
                      <RequireAuth>
                        <UserMenu />
                      </RequireAuth>
                    }
                  >
                    <Route
                      index
                      element={
                        <RequireAuth>
                          <UserPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="user-profile"
                      element={
                        <RequireAuth>
                          <UserPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="user-posts"
                      element={
                        <RequireAuth>
                          <MyPosts />
                        </RequireAuth>
                      }
                    />
                  </Route>
                  <Route
                    path="post/:uid"
                    element={[
                      <RequireAuth>
                        <PostPage />
                        <Background className="bg-white" />
                      </RequireAuth>,
                    ]}
                  />
                </Routes>
              </div>
              <div className="">
                <MobileNavbar />
              </div>
              <div className="">
                <Footer />
              </div>
            </div>
          </Router>
        )}
      </div>
    </Provider>
  );
}

export default App;
