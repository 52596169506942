import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

type InputType = "text" | "password";

const usePasswordToggle = (): [InputType, JSX.Element] => {
  const [visible, setVisibility] = useState<boolean>(false);

  const Icon: JSX.Element = (
    <FontAwesomeIcon
      icon={visible ? faEyeSlash : faEye}
      onClick={() => setVisibility((visibility) => !visibility)}
    />
  );

  const inputType: InputType = visible ? "text" : "password";

  return [inputType, Icon];
};

export default usePasswordToggle;
