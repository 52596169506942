import { useParams } from "react-router";
import usePostPage from "../../constants/getPost/getPost";
import { IPostInputs } from "../../constants/types/types";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUser,
  faEye,
  faMountainCity,
  faPhone,
  faWeightHanging,
} from "@fortawesome/free-solid-svg-icons";
import useUserData from "../../constants/getUser/getUser";
import { doc, updateDoc } from "firebase/firestore";
import firebase from "firebase/compat/app";
import { useForm } from "react-hook-form";
import { kgData } from "../../constants/data/kgData";
import { Link } from "react-router-dom";

export const PostPage = () => {
  const { register, setValue, handleSubmit } = useForm<IPostInputs>();

  const { uid } = useParams();
  const { currentUser } = useUserData();
  const post: IPostInputs | undefined = usePostPage(uid as string);

  const [localPost, setLocalPost] = useState<IPostInputs | undefined>();
  const [imageSelected, setImageSelected] = useState(localPost?.imageURLs?.[0]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [overlayVisible, setOverlayVisible] = useState(false);

  const isUser = currentUser?.uid === localPost?.userUid;

  useEffect(() => {
    setLocalPost(post);
  });

  useEffect(() => {
    if (localPost?.imageURLs && localPost.imageURLs.length > 0) {
      setImageSelected(localPost.imageURLs[0]);
    }
  }, [localPost]);

  const db = firebase.firestore();
  const docRef = doc(db, "posts", `${uid}`);

  useEffect(() => {
    if (localPost) {
      setValue("fromCity", localPost?.fromCity);
      setValue("toCity", localPost?.toCity);
      setValue("weight", localPost?.weight);
      setValue("weightUnit", localPost?.weightUnit);
      setValue("describeCargo", localPost?.describeCargo);
    }
  }, [localPost, setValue]);

  const onSubmit = async (formData: IPostInputs) => {
    const { fromCity, toCity, weight, describeCargo } = formData;
    try {
      await setOverlayVisible(!overlayVisible);
      await updateDoc(docRef, {
        fromCity: fromCity,
        toCity: toCity,
        weight: weight,
        describeCargo: describeCargo,
      });
      await window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  if (!localPost) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div
      className=" py-4
      sm:my-4
      w-full sm:max-w-[700px]
      m-auto  
      bg-white sm:rounded
      xs:pb-14
      
      "
    >
      {/* page header */}

      {/* image body */}
      <div className="px-4 pb-8">
        {imageSelected && (
          <div className="flex flex-col gap-1 max-w-[400px] m-auto">
            <div className="flex justify-center bg-gray-200 rounded">
              <img src={imageSelected} className="w-64" />
            </div>
            <hr className="bg-black" />
            <div className="flex gap-3 p-2 bg-gray-300 rounded overflow-x-scroll">
              {localPost.imageURLs?.map((image, i) => (
                <>
                  <img
                    src={image}
                    key={i}
                    onClick={() => setImageSelected(image)}
                    className="cursor-pointer w-16"
                  />
                </>
              ))}
            </div>
          </div>
        )}

        {/* page description */}
        <hr className="bg-black mt-1" />
        <div className="text-sm text-gray-700 pr-1 text-end py-2">
            <FontAwesomeIcon icon={faEye}/>
            <span>{localPost.views}</span>
          </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" grid grid-cols-1 gap-4"
        >
          
          <div
            className=" bg-gray-200  p-4  rounded
      sm:rounded-t flex justify-between items-center
      "
          >
            <div className="flex items-center gap-2 text-lg ">
              <FontAwesomeIcon
                icon={faCircleUser}
                className="text-gray-700 text-3xl  border-gray-400 border rounded-full"
              />
              <span className="w-full">{localPost?.displayName}</span>
            </div>
            <div className="text-white flex items-center gap-1 py-1 px-2 bg-[#2c49a8] hover:bg-blue-500 rounded-md cursor-pointer shadow-md shadow-gray-600">
              <FontAwesomeIcon icon={faPhone} />
              <Link to={`tel:+996${localPost?.phoneNumber}`}>
                +996{localPost?.phoneNumber}
              </Link>
            </div>
          </div>
          {/* Cities */}
          <div className="flex xs:flex-col gap-4 w-full">
            <div className="w-full">
              <span className=" font-bold">От: </span>
              <p className="flex items-center gap-2 p-2 bg-gray-200 rounded">
                <FontAwesomeIcon icon={faMountainCity} />
                <select
                  {...register("fromCity")}
                  disabled={isDisabled}
                  className={`
                  w-full bg-inherit  outline-none  opacity-100
                  ${isDisabled && "appearance-none"}
                  `}
                  // style={{color:'black', opacity:1}}
                >
                  <option value="" className="text-black font-bold">
                    {localPost?.fromCity}
                  </option>
                  {kgData.map((c, i) => (
                    <option key={i} value={c.label}>
                      {c.label}
                    </option>
                  ))}
                </select>
              </p>
            </div>
            <div className="w-full">
              <span className=" w-full font-bold">До: </span>
              <p className="flex items-center gap-2 p-2 bg-gray-200 rounded">
                <FontAwesomeIcon icon={faMountainCity} />
                <select
                  {...register("toCity")}
                  disabled={isDisabled}
                  className={`
                  w-full bg-inherit outline-none opacity-100
                  ${isDisabled && "appearance-none"}
                  `}
                >
                  <option value="">{localPost?.toCity}</option>
                  {kgData.map((c, i) => (
                    <option key={i} value={c.label}>
                      {c.label}
                    </option>
                  ))}
                </select>
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="w-full font-bold">Вес груза: </span>
            <p className="flex items-center gap-2 p-2 bg-gray-200 rounded">
              <FontAwesomeIcon icon={faWeightHanging} />
              <input
                {...register("weight")}
                disabled={isDisabled}
                defaultValue={localPost?.weight}
                className="w-full bg-inherit outline-none opacity-100"
              />
              <select
                {...register("weightUnit")}
                disabled={isDisabled}
                className={` border-l border-gray-600 pl-1
                   bg-inherit outline-none 
                  ${isDisabled && "appearance-none text-black bg-black"}`}
                placeholder="тонна, килограмм или грамм"
              >
                <option disabled defaultValue={""}>
                  {localPost.weightUnit}
                </option>
                <option value={"тонна"} className="">
                  тонна
                </option>
                <option value={"килограмм"} className="">
                  килограмм
                </option>
              </select>
            </p>
          </div>
          <div className="flex flex-col">
            <span className="w-full font-bold">Описание груза: </span>
            <p className="flex items-center gap-2 p-2 bg-gray-200 rounded">
              <textarea
                {...register("describeCargo")}
                disabled={isDisabled}
                defaultValue={localPost?.describeCargo}
                className="w-full bg-inherit outline-none"
              />
            </p>
          </div>
          {isUser && (
            <div className="w-full">
              {isDisabled ? (
                <button
                  onClick={() => [setIsDisabled(false)]}
                  className="w-full bg-blue-600  text-white  px-1 py-2 rounded"
                >
                  Изменить
                </button>
              ) : (
                <div className="flex xs:flex-col gap-2 w-full">
                  <button
                    className="bg-green-600  text-white  px-1 py-2 rounded
                  shadow-md shadow-gray-600
                  w-full
                  "
                    type="submit"
                  >
                    Принять
                  </button>

                  <button
                    className="bg-red-700  text-white  px-1 py-2 rounded
                  shadow-md shadow-gray-600
                  w-full
                  "
                    onClick={() => window.location.reload()}
                  >
                    Отменить
                  </button>
                </div>
              )}
            </div>
          )}
        </form>
      </div>
      {/*overlay screen to prevent clicks after update*/}
      {overlayVisible && (
        <div className="fixed inset-0 bg-gray-100 backdrop-blur-[30px] opacity-70 z-[70]"></div>
      )}
    </div>
  );
};
