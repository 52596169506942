// import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  doc,
  setDoc,
  serverTimestamp,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

import {
  SubmitHandlerSignUp,
  countryCode,
} from "../../../constants/types/types";
import { auth, db } from "../../../firebase";
import usePasswordToggle from "../../../components/showPassword/showPassword";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  reCaptchaKey,
  reCaptchaSecurityKey,
} from "../../../constants/reCaptcha/reCaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export const SignUp = (): JSX.Element => {
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [phone, setPhone] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      password: "",
      phoneNumber: "",
      displayName: "",
    },
  });

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit: SubmitHandlerSignUp = async (formState) => {
    try {
      const { phoneNumber, password, displayName } = formState;
      const usersCollectionRef = collection(db, "users");
      const usersQuery = query(
        usersCollectionRef,
        where("phoneNumber", "==", phoneNumber)
      );
      const existingUsers = await getDocs(usersQuery);

      if (!existingUsers.empty) {
        setErrorMessage("Пользователь с таким номером уже существует!");
        return;
      }
      const res = await createUserWithEmailAndPassword(
        auth,
        `${countryCode}${phoneNumber}@gmail.com`,
        password as string
      );

      if (auth.currentUser) {
        await updateProfile(auth.currentUser, {
          displayName: displayName,
        });
      }
      await setDoc(doc(db, "users", res.user.uid), {
        ...formState,
        id: res.user.uid,
        timeStamp: serverTimestamp(),
        phoneNumber: phoneNumber,
      });
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  const [captchaDone, setCaptchaDone] = useState(false);
  const submitReCaptcha = () => {
    setCaptchaDone(!captchaDone);
  };
  console.log(captchaDone);

  return (
    <div
      className="
      sm:my-10
      sm:bg-white sm:backdrop-blur-[30px]
      sm:shadow-gray-600 sm:shadow-lg  
      sm:rounded-3xl sm:border 
      sm:max-w-[450px] xs:max-w-[440px]
      sm:px-10 pt-10 pb-7 mx-auto  
      xs:px-6"
    >
      <div>
        <h1 className="text-center">Грузоперевозки</h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4 ">
          <div>
            <div className="text-start">
              <label className="">Введите имя</label>
              <div className="py-2">
                <input
                  placeholder="Камчы"
                  type="text"
                  {...register("displayName", {
                    required: true,
                  })}
                  className={`block 
                  ${
                    errors.displayName
                      ? "border-red-600"
                      : "hover:border-blue-400"
                  }
                  backdrop-blur-[20px] outline-none border-black border-b bg-transparent
                  text-sm w-full p-1.5`}
                />
                <span className="text-red-500 text-xs">
                  {errors.displayName && "Имя обязателен"}
                </span>
              </div>
            </div>
            <div className="text-start">
              <label className="">Введите номер</label>
              <div className="py-2 flex flex-col gap-1">
                <div className="flex">
                  <input
                    type="text"
                    value={countryCode}
                    disabled
                    className={`block 
                    ${
                      errors.phoneNumber
                        ? "border-red-600"
                        : "hover:border-blue-400"
                    }
                    max-w-[35px] bg-transparent border-b
                  text-sm border-black`}
                  />
                  <input
                    type="tel"
                    placeholder="777435261"
                    maxLength={9}
                    // value={phone}
                    {...register("phoneNumber", {
                      required: true,
                    })}
                    className={`block 
                    ${
                      errors.phoneNumber
                        ? "border-red-600"
                        : "hover:border-blue-400"
                    }
                  backdrop-blur-[20px] outline-none border-black border-b bg-transparent
                  text-sm w-full py-1.5`}
                  />
                </div>
                <span className="text-red-500 text-xs">
                  {errors.phoneNumber && "Номер телефона обязателен"}
                </span>
              </div>
            </div>

            <div className="relative">
              <label className="pb-4">Введите пароль</label>
              <input
                placeholder="******"
                type={PasswordInputType}
                id="password"
                {...register("password", {
                  required: true,
                })}
                className={`block 
                ${errors.password ? "border-red-600" : "hover:border-blue-400"}
                  backdrop-blur-[20px] outline-none border-black border-b bg-transparent
                  text-sm w-full p-1.5`}
              />
              <span className="absolute top-[26px] right-[10px] z-50 cursor-pointer ">
                {ToggleIcon}
              </span>
              <span className="text-red-500 text-xs">
                {errors.password && "Пароль обязателен!"}
              </span>
            </div>
          </div>
          <div className="text-start my-2">
            <span>
              <Link
                to="/phone-sign-in"
                className="hover:text-blue-900 focus:text-blue-600 text-blue-700"
              >
                Есть аккаунт? Логин
              </Link>
            </span>
          </div>
          <div className="mb-2 w-full">
            <ReCAPTCHA
              hl="ru"
              sitekey={'6Lcr5JwoAAAAAMU73K4oMeHX8nKvEBwOZ2zEHcPS'}
              onChange={submitReCaptcha}
            />
          </div>

          <div className="">
            <button
              disabled={!captchaDone}
              type="submit"
              className={`rounded
               
                w-full py-1.5 text-white
                ${
                  captchaDone
                    ? "hover:bg-blue-500 focus:bg-blue-600 bg-blue-700"
                    : "bg-gray-400 cursor-not-allowed"
                }`}
            >
              Регистрация
            </button>
          </div>
          <div className="text-sm text-red-500 mt-2 text-center">
            {errorMessage && <div>{errorMessage}</div>}
          </div>
        </div>
      </form>
      <button className="mt-2">
        <Link
          to={"/email-sign-up"}
          className="bg-indigo-600 hover:bg-blue-700 text-white px-2 p-1 rounded-full flex items-center gap-2"
        >
          <FontAwesomeIcon icon={faEnvelope} />
          <span>Регистрация с почты?</span>
        </Link>
      </button>
    </div>
  );
};
