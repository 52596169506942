import { useEffect, useState } from "react";
import { IPostInputs } from "../types/types";
import firebase from "firebase/compat/app";

export const useGetPosts = () => {
  const [posts, setPosts] = useState<IPostInputs[]>([]);

  useEffect(() => {
    const unsubscribePosts = firebase
      .firestore()
      .collection("posts")
      .orderBy("time", "desc")
      .onSnapshot((snapshot) => {
        const fetchedPosts: IPostInputs[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data() as IPostInputs;
          // Convert the Firestore timestamp to a JavaScript Date object
          if (data.time instanceof firebase.firestore.Timestamp) {
            data.time = data.time.toDate();
          }
          fetchedPosts.push(data);
        });

        setPosts(fetchedPosts);
      });

    return () => {
      unsubscribePosts();
    };
  }, []);
  return { posts };
};
