import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const CheckEmail = () => {
  return (
    <div className="sm:w-3/4  xs:max-w-[90%] m-auto">
      <h1 className="text-xl text-center">Грузоперевозки</h1>
      <div className=" bg-gray-100 p-2 rounded mt-2">
        <span className="mr-1">
          <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#1abc3a" }} />
        </span>
        <span>
          Мы отправили вам ссылку на Вашу электронную почут. Пожалуйста,
          проверьте и перейдите по ссылке для изменения пароля.{" "}
          <Link
            to={"/email-sign-in"}
            className="text-blue-600 cursor-pointer underline hover:text-blue-500"
          >
            <span>Нажмите сюда для входа</span>
          </Link>
        </span>
      </div>
    </div>
  );
};
