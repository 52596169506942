import { useEffect, useState } from "react";
import useUserData from "../../constants/getUser/getUser";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { updateProfile } from "firebase/auth";
import { auth, db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPencilAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

type IUpdateProfile = { displayName: string };

export const UserPage = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      displayName: "",
    },
  });

  const { userData } = useUserData();
  const [isClicked, setIsClicked] = useState(false);
  const [toShow, setToShow] = useState(false);

  useEffect(() => {
    if (userData && userData.displayName) {
      setValue("displayName", userData?.displayName);
    }
  }, [userData, setValue]);

  const updateUserPasswordInFirestore = async (
    uid: string,
    displayName: string
  ) => {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, { displayName: displayName });
  };

  const submitDisplayName = async (formState: IUpdateProfile) => {
    const { displayName } = formState;
    const uid = auth.currentUser?.uid;

    try {
      if (auth.currentUser && uid) {
        await updateProfile(auth.currentUser, {
          displayName: displayName,
        });
        await updateUserPasswordInFirestore(uid, displayName);
        await window.location.reload();
      }
    } catch (error) {
      return error;
    }
  };

  const handleDeleteAccount = () => {
    if (window.confirm("Вы точно хотите удалить аккаунт?")) {
      auth?.currentUser
        ?.delete()
        .then(() => {
          // Account deleted successfully
          console.log("Account deleted");
        })
        .catch((error) => {
          // Handle any errors that occurred during the account deletion
          console.error(error.message);
        });
    }
  };

  return (
    <div
      className="
    sm:bg-white
    sm:shadow-gray-600 sm:shadow-md
    sm:border sm:rounded
    w-full 
     py-10 px-5"
    >
      <form onSubmit={handleSubmit(submitDisplayName)}>
        {/* <div>
          <h1 className="text-lg font-semibold text-center">
            Личная Информация
          </h1>
        </div> */}
        <ul className="">
          <li className="flex xs:flex-col sm:flex-col md:flex-row xs:gap-2 items-center xs:border-gray-400  py-0">
            <span className="w-full">Имя</span>
            {isClicked ? (
              <div className="flex gap-2 items-center w-full">
                <input
                  defaultValue={userData?.displayName}
                  {...register("displayName", { required: true })}
                  className={`block 
                hover:border-blue-600 active:border-black
                outline-none border-b border-cyan-500 bg-inherit
                text-sm w-full p-1.5`}
                />
                <button type="submit">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="pb-1 text-green-600 hover:text-green-900 text-lg cursor-pointer"
                  />
                </button>

                <button>
                  <FontAwesomeIcon
                    onClick={() => setIsClicked(false)}
                    icon={faTimes}
                    className="pb-1 text-red-600 hover:text-red-900 text-lg cursor-pointer"
                  />
                </button>
              </div>
            ) : (
              <div className="flex items-center gap-2 w-full">
                <span>{userData?.displayName}</span>
                <FontAwesomeIcon
                  className="pb-3 cursor-pointer text-yellow-600 hover:text-yellow-950  "
                  onClick={() => setIsClicked(true)}
                  icon={faPencilAlt}
                />
              </div>
            )}
          </li>
          <li className="flex xs:flex-col sm:flex-col md:flex-row  xs:gap-2 items-center xs:border-gray-400 border-t py-5">
            <span className="w-full">Номер телефона</span>

            <p className="w-full">{userData?.phoneNumber}</p>
          </li>
          {userData.email && (
            <li className="flex xs:flex-col sm:flex-col md:flex-row  xs:gap-2 items-center xs:border-gray-400 border-t py-5">
              <span className="w-full">Почта</span>

              <p className="w-full">{userData?.email}</p>
            </li>
          )}
          {/* <li className="flex xs:flex-col sm:flex-col md:flex-row  xs:gap-2 items-center xs:border-gray-400 border-t py-5">
            <span className="w-full">Пароль</span>
            <div className="flex items-center gap-2 w-full">
              <input type="checkbox" onClick={() => setToShow(!toShow)}></input>
              {!toShow ? (
                <span className="pt-[1px] ">********</span>
              ) : (
                <span className="pb-1">{userData.password}</span>
              )}
            </div>
          </li> */}
        </ul>

        <div className="flex gap-2">
          <Link
            to={"/new-password"}
            className="rounded px-1 py-1 bg-blue-600  hover:bg-blue-700 text-white text-sm"
          >
            Поменять пароль
          </Link>
          <button onClick={handleDeleteAccount} className="rounded px-1 py-1 bg-red-600  hover:bg-red-700 text-white text-sm">
            Удалить аккаунт
          </button>
        </div>
      </form>
    </div>
  );
};
