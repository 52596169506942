import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import { deleteDoc, doc } from "firebase/firestore";

import { IPostInputs } from "../../constants/types/types";
import truck from "../../images/truck.png";
import { excerpt } from "../../utilities/excerpt/excerpt";
import { db } from "../../firebase";

type PostingCardProps = {
  filteredPosts: IPostInputs[];
  isUserPosts: boolean;
  className: string;
};

export const PostingCard: React.FC<PostingCardProps> = ({
  filteredPosts,
  isUserPosts,
  className,
}) => {
  const handleDelete = async (id: any) => {
    try {
      await deleteDoc(doc(db, "posts", id));
    } catch {
      console.log("could not delete");
    }
  };

  const updateViewsCount = (postId: string | undefined) => {
    const db = firebase.firestore();
    const postRef = db.collection("posts").doc(postId);
    try {
      postRef.update({
        views: firebase.firestore.FieldValue.increment(1),
      });
    } catch {
      console.log("error incrementing");
    }
  };

  return (
    <div className={`${className}`}>
      {filteredPosts.map((cardItems, i) => (
        <div
          key={i}
          className={`
          bg-white rounded
          mx-auto 
          w-full
          p-1.5 xs:p-0 
          text-sm
          shadow-gray-600 sm:shadow-md shadow
          `}
        >
          <div className="grid grid-cols-3 gap-2 px-2 xs:p-4 w-full h-full">
            {/* date and name */}
            <div className="col-span-full text-xs flex text-center justify-between items-center px-3 place-item-start ">
              <p className="">
                {cardItems.time?.toLocaleString("ru-Ru", {
                  timeZone: "Asia/Bishkek",
                  hour12: false,
                })}
              </p>
              <p className="font-semibold text-start text-black bg-gray-300 rounded p-0.5 px-1 order-1">
                {cardItems.displayName}
              </p>
            </div>
            {/* image */}
            <div
              className="
            col-span-full xs:col-span-1 
            "
            >
              {cardItems.imageURLs && cardItems.imageURLs.length > 0 ? (
                <img
                  className="sm:h-48 h-24 w-full object-cover"
                  src={cardItems.imageURLs[0]}
                  alt="Uploaded content"
                />
              ) : (
                <img
                  className="sm:h-48 w-full h-24 object-cover"
                  src={truck}
                  alt="sonic"
                />
              )}
            </div>
            {/* cargo data */}
            <div className="col-span-full xs:col-span-2 w-full place-item-end">
              <div className="sm:order-1">
                <div>
                  <p>
                    <span className="font-semibold">От: </span>
                    <span>{cardItems.fromCity}</span>
                  </p>
                  <p>
                    <span className="font-semibold">До: </span>
                    <span>{cardItems.toCity}</span>{" "}
                  </p>
                  <p>
                    <span className="font-semibold">Вес груза: </span>{" "}
                    <span>
                      {cardItems.weight} {cardItems.weightUnit}
                    </span>
                  </p>
                </div>
                <div className="">
                  <p>
                    <span className="font-semibold">Описание груза: </span>
                    <span className="">
                      {excerpt(`${cardItems.describeCargo}`, 18)}
                    </span>
                  </p>
                </div>
                <div>
                  <p className="">
                    {/* <span className="font-semibold">Номер телефона: </span>
                    <Link to={`tel:${cardItems.phoneNumber}`}>
                      <span
                        className={`${
                          i % 2 === 0 ? "text-cyan-300" : "text-blue-900"
                        }`}
                      >
                        +996{cardItems.phoneNumber}
                      </span>
                    </Link> */}
                  </p>
                </div>

                <Link to={`/post/${cardItems.docId}`}>
                  <button
                    onClick={() => updateViewsCount(cardItems.docId)}
                    className=" px-2 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded text-xs"
                  >
                    Узнать больше
                  </button>
                </Link>
                {isUserPosts ? (
                  <div className="flex gap-2 mt-2">
                    <button
                      onClick={() => {
                        handleDelete(cardItems.docId);
                      }}
                      className="p-1 rounded text-white bg-red-700 text-xs px-1.5"
                    >
                      Удалить
                    </button>{" "}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
