// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "@firebase/storage";

export const app = firebase.initializeApp({
  apiKey: "AIzaSyAad81btLecGtAAo-1tCHQU088Ll2fphao",
  authDomain: "baikut-tracking.firebaseapp.com",
  projectId: "baikut-tracking",
  storageBucket: "baikut-tracking.appspot.com",
  messagingSenderId: "723878875816",
  appId: "1:723878875816:web:e1842fdaa96f6bce59e89d",
  measurementId: "G-GF6T65XE12"

  
});

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
  // Add the prompt option to the provider
  provider.setCustomParameters({
    prompt: 'select_account',
  });

  // Sign in with the modified provider
  signInWithPopup(auth, provider)
    .then((result) => {
      console.log(result);
    })
    .catch((error) => console.log(error));
};