// import { Link } from "react-router-dom";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { sendPasswordResetEmail } from "firebase/auth";

import { IForgotPassword } from "../../../constants/types/types";
import { auth } from "../../../firebase";
import { useUsers } from "../../../constants/getUsers/getUsers";

export const SendPasswordLinkToEmail = (): JSX.Element => {
  const navigate = useNavigate();
  const { users } = useUsers();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IForgotPassword>();
  const watchData = watch();

  const userUID = users.find((x) => x.email === watchData.email);

  const [authErrorMessage, setAuthErrorMessage] = useState<string>("");

  const onSubmit: SubmitHandler<IForgotPassword> = async (formState) => {
    try {
      await sendPasswordResetEmail(auth, formState.email);
      await navigate(`/check-email`);
    } catch (error) {
      setAuthErrorMessage(
        "Пользователь не найден. Пожалуйста, введите правильно почту"
      );
    }
  };

  return (
    <div className="text-sm mt-10  max-w-[400px]  m-auto">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4 sm:bg-white py-5 shadow sm:rounded-lg xs:shadow-none sm:px-10 xs:px-6">
          <div>
            <h1 className="font-bold text-base text-center mb-2">
              Запрос для сброса пароли
            </h1>
            <div className="text-start">
              <label className="" htmlFor="phoneNumber">
                Введите электронную почту
              </label>
              <div className="py-2">
                <input
                  type="email"
                  id="email"
                  placeholder="kamchy996@mail.com"
                  {...register("email", {
                    required: true,
                  })}
                  className={`block text-sm border ${
                    errors.email ? "outline-red-500" : "outline-blue-500"
                  } rounded border-gray-300 p-1.5 w-full`}
                />
                {authErrorMessage && (
                  <span className="text-red-500 text-xs">
                    {authErrorMessage}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="rounded hover:bg-blue-500 focus:bg-blue-600 bg-blue-700 w-full py-1.5 text-white"
            >
              Запрос
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
