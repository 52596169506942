import "firebase/compat/firestore";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";

import {
  faPlusCircle,
  faUserCircle,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";

import "../../constants/loader/loader.css";
import { AuthContext } from "../../context/AuthContex";

export const MobileNavbar = () => {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();

  return (
    currentUser && (
      <nav className="xs:block hidden">
        <div className="relative">
          <div
            className={`z-50
        fixed bottom-0 left-0 w-full 
        grid grid-cols-3 items-center text-center
        bg-blue-900
      `}
          >
            <Link
              to="/"
              className={`${
                location.pathname === "/" && "bg-blue-400"
              } flex flex-col gap-1  p-1 pt-2 `}
            >
              <FontAwesomeIcon
                icon={faHouse}
                className="text-3xl text-white active:bg-none "
              />
              <span className="text-xs text-white">Главная</span>
            </Link>
            <Link
              to="/create-post"
              className={`${
                location.pathname === "/create-post" && "bg-blue-400"
              } flex flex-col gap-1  p-1 pt-2 `}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                className="text-3xl text-white"
              />
              <span className="text-xs text-white">Подать</span>
            </Link>
            <Link
              to={"/user-menu"}
              className={`${
                location.pathname.startsWith("/user-menu")  && "bg-blue-400"
              } flex flex-col gap-1  p-1 pt-2 `}
            >
              <FontAwesomeIcon
                icon={faUserCircle}
                className="text-3xl text-white "
              />
              <span className="text-xs text-white">Профиль</span>
            </Link>
          </div>
        </div>
      </nav>
    )
  );
};
