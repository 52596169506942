import {
  faAddressCard,
  faList,
  faRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { signOut } from "firebase/auth";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { useState } from "react";

export const UserMenu = () => {
  const [activeItem, setActiveItem] = useState<number | null>(0);

  const handleItemClick = (index: number) => {
    setActiveItem(index);
  };

  const navigate = useNavigate();

  const logout = async () => {
    await signOut(auth);
    navigate("/phone-sign-in");
  };

  const location = useLocation();

  return (
    <div
      className="sm:px-6 
    component xs:p-2  xs:pt-10 xs:bg-gray-100  rounded p-6  xs:h-screen xs:w-screen
    "
    >
      <h1 className="text-center mb-5">Личный кабинет</h1>
      <div className="flex xs:flex-col sm:items-start sm:justify-center gap-5 ">
        <ul
          className="w-full sm:min-w-[200px]
          px-2 text-center
          grid xs:grid-cols-3 sm:grid-cols-1 sm:gap-2 gap-5
          sm:max-w-[250px] sm:bg-white sm:rounded p-4
          sm:shadow-gray-600 sm:shadow-md
          "
        >
          <Link
            to={"user-profile"}
            className={`py-3 p-1 rounded-md hover:bg-blue-200 
            ${
              location.pathname.includes("user-profile")
                ? "bg-blue-200"
                : "bg-inherit xs:bg-gray-300"
            }  w-full sm:shadow-none shadow-xl`}
          >
            <li className="flex sm:flex-row flex-col gap-1 items-center overflow-hidden">
              <FontAwesomeIcon
                icon={faAddressCard}
                className="sm:text-xl text-3xl text-blue-500"
              />
              <span className="sm:text-base text-xs">Личные данные</span>
            </li>
          </Link>

          <Link
            to={"user-posts"}
            className={`py-3 p-1 rounded-md hover:bg-blue-200 
            ${
              location.pathname === "user-posts"
                ? "bg-blue-200"
                : "bg-inherit xs:bg-gray-300"
            }  w-full sm:shadow-none shadow-xl`}
          >
            <li className="flex sm:flex-row flex-col gap-1 items-center overflow-hidden">
              <FontAwesomeIcon
                icon={faList}
                className="sm:text-xl text-3xl text-blue-500"
              />
              <span className="sm:text-base text-xs">Мои объявления</span>
            </li>
          </Link>
          <li className=" p-1 py-3 rounded-md sm:hidden bg-gray-300 shadow-xl">
            <button
              className="flex flex-col items-center gap-1 m-auto overflow-hidden"
              onClick={logout}
            >
              <FontAwesomeIcon
                icon={faRightToBracket}
                className="sm:text-xl text-3xl text-blue-500"
              />
              <span className="text-xs">Выйти</span>
            </button>
          </li>
        </ul>
        <div className="max-w-[600px] xs:min-w-[200px] min-w-[400px] ">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
