import { useState, useEffect } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { User, onAuthStateChanged } from "firebase/auth";

import { ISignUpInputs } from "../types/types";
import { auth } from "../../firebase";

function useUserData() {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userData, setuserData] = useState<ISignUpInputs>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setCurrentUser(currentUser);
      if (currentUser) {
        const firestore = getFirestore();
        const userDocRef = doc(firestore, "users", currentUser.uid);
        getDoc(userDocRef)
          .then((userSnapshot) => {
            if (userSnapshot.exists()) {
              const userData = userSnapshot.data();
              setuserData(userData);
            }
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });

    return unsubscribe;
  }, []);
  return { currentUser, userData, loading };
}

export default useUserData;
