import wave from "../../images/wave.svg";
import wave1 from "../../images/wave-1.svg";
import wave2 from "../../images/wave2.svg";
import wave3 from "../../images/wave3.svg";
import wave4 from "../../images/wave4.svg";

import "./background.css";

type BakgroundColor = {
  className:string
}

export const Background: React.FC<BakgroundColor> = ({className}) => {
  return (
    <div className={`header ${className}`}>
      {/* <img src={wave} />
      <img src={wave1} />
      <img src={wave2} />
      <img src={wave3} /> */}
      <img src={wave4} id="shape" />
    </div>
  );
};
