import "firebase/compat/firestore";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState, useEffect, useRef } from "react";
import { signOut } from "firebase/auth";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

import { auth } from "../../firebase";
import "../../constants/loader/loader.css";
import { AuthContext } from "../../context/AuthContex";

export const Navbar = () => {
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  const { currentUser } = useContext(AuthContext);

  const navigate = useNavigate();
  const logout = async () => {
    await signOut(auth);
    navigate("/phone-sign-in");
  };

  const location = useLocation();

  useEffect(() => {
    setIsMenuClicked(false);
  }, [location]);

  const navbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target as Node)
      ) {
        setIsMenuClicked(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav ref={navbarRef} className="xs:hidden block bg-blue-900 text-white ">
      {currentUser && (
        <div className="">
          <div
            className=" w-full bg-transparent
          top-0 left-0 sticky flex  justify-between
          items-center pb-3  pt-3  lg:px-10 px-8 easy-in duration-600 z-50"
          >
            <div className=" ">
              <Link to="/" className="">
                <span
                  className={`text-xl font-semibold  ${
                    isMenuClicked && "text-white ease-in duration-500"
                  } hover:text-blue-700/70`}
                >
                  Грузоперевозки
                </span>
              </Link>
            </div>

            <div
              className={`bg-blue-900 l lg:shadow-none shadow-black shadow-2xl text-white
               lg:flex lg:items-center z-[-1] 
              lg:z-[60] lg:static absolute w-full left-0 lg:w-auto lg:py-0 py-4 lg:pl-0 pl-7
              lg:opacity-100 transition-all ease-in duration-500 ${
                isMenuClicked
                  ? "top-0 opacity-100 pl-10 pt-16"
                  : "opacity-0 top-[-400px] "
              }`}
            >
              <ul className=" text-gray-200  flex flex-col lg:flex-row lg:gap-3 text-base lg:text-base  lg:mr-5 xs:gap-3 sm:gap-3 md:gap-3">
                <li className=" hover:text-blue-600/95">
                  <Link to="/">Объявления</Link>
                </li>
                <li className=" hover:text-blue-600/95">
                  <Link to="/create-post">Подать объявление </Link>
                </li>
                <li className=" hover:text-blue-600/95">
                  <Link to="/user-menu">Личный кабинет</Link>
                </li>
              </ul>
              <div className="mt-4 lg:mt-0">
                <div className="flex gap-2  items-center ">
                  {/* <Link
                    to={"/user-posts"}
                    className="lg:text-white  p-1 lg:px-2 lg:bg-gray-500 rounded lg:ml-2"
                  >
                    {currentUser?.displayName}
                  </Link> */}
                  <button
                    className=" bg-gray-300 hover:bg-gray-700 text-black focus:bg-blue-400/hover:text-blue-600/95 py-1 pb-1.5 rounded px-2 text-lg"
                    onClick={logout}
                  >
                    Выйти
                  </button>
                </div>
              </div>
            </div>
            <span className="cursor-pointer lg:hidden block mx-2 text-2xl ">
              <FontAwesomeIcon
                icon={isMenuClicked ? faClose : faBars}
                onClick={() => setIsMenuClicked(!isMenuClicked)}
              />
            </span>
          </div>
        </div>
      )}
    </nav>
  );
};
