import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { IPostInputs } from "../types/types";

const usePostPage = (uid: string) => {
  const [product, setProduct] = useState<IPostInputs|undefined>();

  useEffect(() => {
    const fetchProduct = async () => {
      const productRef = firebase.firestore().collection("posts").doc(uid);
      const doc = await productRef.get();

      if (doc.exists) {
        setProduct(doc.data() as IPostInputs);
      } else {
        console.log("No such document!");
      }
    };

    fetchProduct();
  }, [uid]);

  return product;
};

export default usePostPage;
