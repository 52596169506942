// import { Link } from "react-router-dom";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { sendPasswordResetEmail } from "firebase/auth";

import { IForgotPassword } from "../../../constants/types/types";
import { auth } from "../../../firebase";
import { Link } from "react-router-dom";
import { FaWhatsappSquare, FaTelegram } from "react-icons/fa";

export const ForgotPassword = (): JSX.Element => {
  const navigation = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPassword>();

  const [authErrorMessage, setAuthErrorMessage] = useState<string>("");

  const onSubmit: SubmitHandler<IForgotPassword> = async (formState) => {
    try {
      await sendPasswordResetEmail(auth, formState.email);
      navigation("/check-email");
    } catch (error) {
      setAuthErrorMessage(
        "Пользователь не найден. Пожалуйста, введите правильно почту"
      );
    }
  };

  return (
    <div className="text-sm mt-10  max-w-[400px]  m-auto">
      <div className="text-center">
        <h1 className="text-lg">Свяжитесь с технической поддержкой</h1>
      </div>
      <ul className="flex flex-row m-auto justify-center mt-4 gap-3">
        <li>
          <Link to={`https://wa.me/996998141200`}>
            <FaWhatsappSquare className="text-green-700 text-3xl" />
          </Link>
        </li>
        <li>
          <Link to={`https://t.me/amanatkg`}>
            <FaTelegram className="text-blue-600 text-3xl" />
          </Link>
        </li>
      </ul>

      {/* <form onSubmit={handleSubmit(onSubmit)}>
        
        <div className="mt-4 bg-white py-5 shadow sm:rounded-lg xs:shadow-none sm:px-10 xs:px-6">
          <div>
            <div className="text-start">
              <label className="" htmlFor="phoneNumber">
                Введите электронную почту
              </label>
              <div className="py-2">
                <input
                  type="email"
                  id="email"
                  {...register("email", {
                    required: true,
                  })}
                  className={`block text-sm border ${
                    errors.email ? "outline-red-500" : "outline-blue-500"
                  } rounded border-gray-300 p-1.5 w-full`}
                />
                {authErrorMessage && (
                  <span className="text-red-500 text-xs">
                    {authErrorMessage}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="rounded hover:bg-blue-500 focus:bg-blue-600 bg-blue-700 w-full py-1.5 text-white"
            >
              Запрос
            </button>
          </div>
        </div>
      </form> */}
    </div>
  );
};
