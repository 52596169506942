import { configureStore } from "@reduxjs/toolkit";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPostInputs } from "../constants/types/types";

interface Post {
  fromCity: string;
  toCity: string;
}

interface PostsState {
  rawPostsData: IPostInputs[];
  filteredCityData: IPostInputs[];
  userPostsData: IPostInputs[];
  selectedFromCity:string, selectedToCity:string
}

const initialState: PostsState = {
  rawPostsData: [], // Store the raw data fetched from Firebase
  filteredCityData: [], // Store the filtered data
  userPostsData: [], // Store the user posts data
  selectedFromCity:'',selectedToCity:''
};
const dataSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setPostsData: (state, action: PayloadAction<IPostInputs[]>) => {
      state.rawPostsData = action.payload;
    },
    filterDataByCities: (
      state,
      action: PayloadAction<{ fromCity: string; toCity: string }>
    ) => {
      const { fromCity, toCity } = action.payload;
      state.filteredCityData = state.rawPostsData.filter(
        (post) => post.fromCity === fromCity && post.toCity === toCity
      );
      // Store the selected cities in the state
      state.selectedFromCity = fromCity;
      state.selectedToCity = toCity;
    },
    eraseFiltering: (state) => {
      state.filteredCityData = [];
    },
    setUserPostsData: (state, action: PayloadAction<{ currentUserUid: string }>) => {
      const { currentUserUid } = action.payload;
      state.userPostsData = state.rawPostsData.filter(
        (post) => post.userUid === currentUserUid
      );
    },
  },
});

export const {
  setPostsData,
  filterDataByCities,
  setUserPostsData,
  eraseFiltering
} = dataSlice.actions;

export const store = configureStore({ reducer: { posts: dataSlice.reducer } });
