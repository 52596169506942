import { confirmPasswordReset } from "firebase/auth";
import { auth } from "../../../firebase";
import { useForm } from "react-hook-form";
import {
  IResetPassword,
  SubmitHandlerReset,
} from "../../../constants/types/types";
import { useNavigate } from "react-router";

export const NewPasswordEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IResetPassword>();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const oobCode = urlParams.get("oobCode");

  // Your reset password function
  const resetPassword: SubmitHandlerReset = async (formState) => {
    const { newPassword } = formState;
    try {
      // Confirm the password reset using the oobCode
      await confirmPasswordReset(auth, oobCode as string, newPassword);
      await navigate("/email-sign-in");
      // Password has been successfully reset
      console.log("Password has been reset successfully.");
    } catch (error) {
      console.error("Password reset failed:", error);
    }
  };

  return (
    <div className="text-sm mt-10  max-w-[400px]  m-auto">
      <form onSubmit={handleSubmit(resetPassword)}>
        <div className="mt-4 sm:bg-white py-5 shadow sm:rounded-lg xs:shadow-none sm:px-10 xs:px-6">
          <div>
            <h1 className="font-bold text-base text-center mb-2">
              Сброса пароли
            </h1>
            <div className="text-start">
              <label className="" htmlFor="phoneNumber">
                Введите новый пароль
              </label>
              <div className="py-2">
                <input
                  type="password"
                  id="newPassword"
                  placeholder="******"
                  {...register("newPassword", {
                    required: true,
                  })}
                  className={`block text-sm border ${
                    errors.newPassword ? "outline-red-500" : "outline-blue-500"
                  } rounded border-gray-300 p-1.5 w-full`}
                />
              </div>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="rounded hover:bg-blue-500 focus:bg-blue-600 bg-blue-700 w-full py-1.5 text-white"
            >
              Запрос
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
