import { ReactNode } from "react";
import firebase from "firebase/compat";
export interface ISignInInputs {
  phoneNumber?: string;
  password?: string;
  email?:string
  id?:string
}

export interface IForgotPassword {
  email: string;
}
export interface IResetPassword {
  newPassword: string;
}

export interface IResetPassword {
  password: string;
  confirmPassword: string;
}

export interface ISignUpInputs {
  phoneNumber?: string | undefined;
  password?: string | undefined;
  displayName?: string;
  email?: string;
  id?:string

}

export interface IPostInputs {
  fromCity?: string;
  toCity?: string;
  weight?: string;
  weightUnit?: string;
  describeCargo?: string;
  lastName?: string;
  phoneNumber?: string | undefined;
  displayName?: string;
  time?: firebase.firestore.Timestamp | Date;
  userUid?: string;
  docId?: string;
  imageURLs?: string[] | undefined;
  views: number | string | undefined;
}

export type SignInName = "phoneNumber" | "password";

export type Option = {
  label: string;
  value: string;
};

export interface FormValues {
  fromCity: Option | null;
  toCity: Option | null;
  weightUnit?: Option | null;
  describeCargo?: string;
  files?: IFile[] | null;
  weight: string;
}
export type IFile = {
  name?: string;
  url: string;
  file?: File | undefined;
};

export interface AuthContextProviderProps {
  children: ReactNode;
}

export interface InitialState {
  currentUser: string | null;
}

export interface AuthState {
  currentUser: string | null | number | any;
}

export type AuthAction =
  | { type: "LOGIN"; payload: string }
  | { type: "LOGOUT" };

export type SubmitHandlerSignIn = (formState: ISignInInputs) => Promise<void>;

export type SubmitHandlerSignUp = (formState: ISignUpInputs) => Promise<void>;

export type SubmitHandlerCreatePost = (formState: IPostInputs) => Promise<void>;

export type SubmitHandlerFilter = (formState: IPostInputs) => Promise<void>;

export type SubmitHandlerReset = (formState: IResetPassword) => Promise<void>;


export interface IFilterInputData {
  toCity: string;
  fromCity: string;
}

export const options: Intl.DateTimeFormatOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const event: Date = new Date();
export const formattedDateTime: string = event.toLocaleString("ru-KG", options);

export interface IFilterInputData {
  toCity: string;
  fromCity: string;
}

export const countryCode = "+996";
