// import { Link } from "react-router-dom";
import { FormEvent, useState } from "react";

import usePasswordToggle from "../../../components/showPassword/showPassword";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import useUserData from "../../../constants/getUser/getUser";
import { doc, getFirestore, updateDoc } from "firebase/firestore";

export type NewPasswordName = "password" | "confirmPassword";
export const NewPassword = (): JSX.Element => {
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [newPassword, setNewPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [changedMessage, setChangedMessage] = useState("");

  const auth = getAuth();
  const user = auth.currentUser;
  const db = getFirestore();

  const { userData } = useUserData();

  const reauthenticate = async (currentPassword: string) => {
    if (user) {
      const cred = EmailAuthProvider.credential(
        user.email as string,
        currentPassword
      );
      await reauthenticateWithCredential(user, cred);
    }
  };

  const updateUserPassword = async (
    currentPassword: string,
    newPassword: string
  ) => {
    try {
      await reauthenticate(currentPassword);
      if (user) {
        await updatePassword(user, newPassword);
      }
    } catch (error) {
      return error;
    }
  };

  const updateUserPasswordInFirestore = async (
    uid: string,
    newPassword: string
  ) => {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, { password: newPassword });
  };
  const currentPassword = userData.password;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const uid = auth.currentUser?.uid;
    if (uid) {
      try {
        await updateUserPassword(currentPassword as string, newPassword);
        await updateUserPasswordInFirestore(uid, newPassword);
        await setIsDisabled(true);

        await setChangedMessage("Пароль успешно изменен!");
      } catch (error) {
        return error;
      }
    }
  };

  return (
    <div
      className="
      component
      sm:bg-zinc-100/30 sm:backdrop-blur-[20px] 
      sm:shadow-gray-800 sm:shadow-2xl
      sm:border rounded-3xl 
      w-full sm:max-w-[400px]
      m-auto my-5 py-10 px-5"
    >
      <div>
        <h1 className="text-center">Новый пароль</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="text-start mt-5 px-5">
          <div>
            <div>
              <label className="" htmlFor="password">
                Введите новый пароль
              </label>
              <div className="py-2 relative">
                <input
                  placeholder="Новый пароль"
                  value={newPassword}
                  type={PasswordInputType}
                  onChange={(e) => [
                    PasswordInputType,
                    setNewPassword(e.target.value),
                    setIsDisabled(!(newPassword.length >= 5)),
                  ]}
                  className={`block 
                  hover:border-blue-400 active:border-black
                  bg-inherit outline-none border-b border-black
                  text-sm w-full p-1.5`}
                />
                <span className="absolute top-[16px] right-[10px] z-50 cursor-pointer ">
                  {ToggleIcon}
                </span>

                <div>
                  <span className="text-red-500 text-xs"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <button
              disabled={isDisabled}
              type="submit"
              className={`${
                isDisabled
                  ? "bg-gray-400 cursor-not-allowed"
                  : "hover:bg-blue-500 focus:bg-blue-600 bg-blue-700"
              } rounded  w-full py-1.5 text-white`}
            >
              Запрос
            </button>
          </div>
          <div className="text-blue-500 text-center mt-2 ">
            {changedMessage}
          </div>
        </div>
      </form>
    </div>
  );
};
