import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className='xs:hidden text-center bg-blue-900/90 py-6 w-full text-white'>
      <p>© {currentYear} Baikut Tracking. Все права защищены.</p>
    </footer>
  );
};

export default Footer;
