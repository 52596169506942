import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { ISignUpInputs } from "../types/types";

export function useUsers() {
  const [users, setUsers] = useState<ISignUpInputs[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      const data = querySnapshot.docs.map((doc) => doc.data());
      setUsers(data);
    };

    fetchUsers();
  }, []);

  return { users };
}
