import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaGoogle } from "react-icons/fa";
import { signInWithEmailAndPassword } from "firebase/auth";

import {
  ISignInInputs,
  SubmitHandlerSignIn,
} from "../../../constants/types/types";
import { auth, signInWithGoogle } from "../../../firebase";
import usePasswordToggle from "../../../components/showPassword/showPassword";

export const SignInWithEmail = (): JSX.Element => {
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignInInputs>();

  const [authErrorMessage, setAuthErrorMessage] = useState<string>("");
  const navigate = useNavigate();

  const onSubmit: SubmitHandlerSignIn = async (formState) => {
    try {
      const { password, email } = formState;
      await signInWithEmailAndPassword(
        auth,
        email as string,
        password as string
      )
        .then(async (userCredential) => {
          var user = userCredential.user;
          console.log("Sign In SuccessFul!", user);
          await navigate("/");
        })
        .catch((error) => {
          console.log(error);
          setAuthErrorMessage(error.message);
        });
    } catch (error: any) {
      setAuthErrorMessage(error.message);
      console.log(authErrorMessage);
    }
  };

  return (
    <div
      className={`
      sm:mt-20
    sm:bg-white sm:backdrop-blur-[30px]
    sm:shadow-gray-600 sm:shadow-lg  
    sm:rounded-3xl sm:border 
    sm:max-w-[450px] xs:max-w-[440px]
    sm:px-10  xs:px-6 pt-10 pb-7 mx-auto `}
    >
      <div>
        <h1 className=" text-center">Грузоперевозки</h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4 pt-5 ">
          <div>
            <div className="text-start">
              <label className="" htmlFor="email">
                Введите почту
              </label>
              <div className="py-2 flex flex-col gap-1">
                <div className="flex">
                  <input
                    type="email"
                    placeholder="kamchy996@gmail.com"
                    {...register("email", {
                      required: true,
                    })}
                    className={`
                    ${
                      errors.phoneNumber
                        ? "border-red-600"
                        : "hover:border-blue-400"
                    }
                    block 
                    backdrop-blur-[20px] outline-none  border-b bg-transparent
                    text-sm w-full py-1.5 border-black`}
                  />
                </div>
                {errors.phoneNumber && (
                  <p className="text-red-500 text-xs ">Неправильная почта</p>
                )}
              </div>
            </div>

            <div className="text-start">
              <label className="" htmlFor="password">
                Введите пароль
              </label>
              <div className="py-2 relative">
                <input
                  type={PasswordInputType}
                  placeholder="******"
                  id={"password"}
                  {...register("password", {
                    required: true,
                  })}
                  className={`block 
                  ${
                    errors.password ? "border-red-600" : "hover:border-blue-400"
                  }
                  backdrop-blur-[20px] outline-none  border-b bg-transparent
                  text-sm w-full p-1.5 border-black`}
                />
                <span className="absolute top-[15px] right-[10px] z-50 cursor-pointer ">
                  {ToggleIcon}
                </span>
                {errors.password && (
                  <span className="text-red-500 text-xs">
                    Неправильный пароль
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="text-start my-2">
            <span>
              <Link
                to="/send-link"
                className="hover:text-blue-900 focus:text-blue-600 text-blue-600"
              >
                Забыли Пароль?
              </Link>
            </span>
          </div>
          <div>
            <button
              type="submit"
              className="
              rounded text-white hover:bg-blue-500 
              focus:bg-blue-600 bg-blue-700 w-full py-1.5 
              "
            >
              Логин
            </button>
          </div>

          <div className="mt-2 flex flex-col gap-1">
            <span>
              <Link
                to="/email-sign-up"
                className="hover:text-blue-900 focus:text-blue-600 text-blue-600"
              >
                Нет аккаунта? Пройти Регистрацию
              </Link>
            </span>
          </div>
          <div className="text-center mt-2">
            {authErrorMessage && (
              <span className="text-center text-red-500">
                Неправильный подбор номера или пароли
              </span>
            )}
          </div>
        </div>
      </form>
      {/* sign in methods */}
      <div className="flex gap-4  justify-start pt-2 xs:text-xs">
        <Link
          to={"/phone-sign-in"}
          className="
            flex  gap-3 items-center 
             px-2 py-2 rounded-full max-w-[190px] text-sm 
             bg-indigo-600 text-white
             shadow-md shadow-black
             cursor-pointer hover:bg-blue-700
             "
        >
          <FontAwesomeIcon icon={faPhone} className="" />
          <span className="xs:text-xs">Войти с номера</span>
        </Link>
        {/* <button
          onClick={signInWithGoogle}
          className="
            flex  gap-3 items-center 
             px-2 py-2 rounded-full max-w-[190px] text-sm 
             bg-indigo-600 text-white
             shadow-md shadow-black
             cursor-pointer hover:bg-blue-700
             "
        >
          <FaGoogle className="" />
          <span>Войти с Гугл аккаунта</span>
        </button> */}
      </div>
    </div>
  );
};
