import { useGetPosts } from "../../constants/getData/getData";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContex";
import { PostingCard } from "../../components/postingCard/postingCard";
import { useSelector } from "react-redux";

export const MyPosts = () => {
  const {posts} = useGetPosts()
  // const { posts } = useSelector((action: any) => action.posts);
  const { currentUser } = useContext(AuthContext);

  const currentUserUid = currentUser?.uid;

  const currentUserPosts = posts.filter(
    (post: any) => post?.userUid === currentUserUid
  );

  return (
    <div className="w-full">
      <PostingCard
        filteredPosts={currentUserPosts}
        isUserPosts={true}
        className="grid xs:grid-cols-1 grid-cols-2 gap-3 px-2 mb-20"
      />
    </div>
  );
};
