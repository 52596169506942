type kgDataType = { label: string; value: string };

export const kgData: kgDataType[] = [
  { label: "Бишкек", value: "Bishkek_Bishkek" },
  { label: "Ош", value: "Osh_Osh" },
  { label: "Жалал-Абад", value: "Jalal-Abad_Jalal-Abad" },
  { label: "Талас", value: "Talas_Talas" },
  { label: "Нарын", value: "Naryn_Naryn" },
  { label: "Баткен", value: "Batken_Batken" },
  { label: "Каракол, Ыссык-Кол", value: "Karakol_Ysyk-Kol" },
  { label: "Кара-Балта, Чуй", value: "Kara-Balta_Chüy" },
  { label: "Токмок, Чуй", value: "Tokmok_Chüy" },
  { label: "Кызыл-Кыя, Баткен", value: "Kyzyl-Kyya_Batken" },
  { label: "Озгон, Ош", value: "ozgon_Osh" },
  { label: "Балыкчы, Ыссык-Кол", value: "Balykchy_Ysyk-Kol" },
  { label: "Базар-Коргон, Жалал-Абад", value: "Bazar-Korgon_Jalal-Abad" },
  { label: "Араван, Ош", value: "Aravan_Osh" },
  { label: "Сокулук, Чуй", value: "Sokuluk_Chüy" },
  { label: "Сузак, Жалал-Абад", value: "Suzak_Jalal-Abad" },
  { label: "Ирадан, Баткен", value: "Iradan_Batken" },
  { label: "Токтогул, Жалал-Абад", value: "Toktogul_Jalal-Abad" },
  { label: "Новопокровка, Чуй", value: "Novopokrovka_Chüy" },
  { label: "Кант, Чуй", value: "Kant_Chüy" },
  { label: "Беловодское, Чуй", value: "Belovodskoe_Chüy" },
  { label: "Шарк, Ош", value: "Shark_Osh" },
  { label: "Кадамжай, Баткен", value: "Kadamjay_Batken" },
  { label: "Жаны-Ноокат, Ош", value: "Jangy-Nookat_Osh" },
  { label: "Кара-Суу, Ош", value: "Kara-Suu_Osh" },
  { label: "Лебединовка, Чуй", value: "Lebedinovka_Chüy" },
  { label: "Куршаб, Ош", value: "Kurshab_Osh" },
  { label: "Кашкар-Кыштак, Ош", value: "Kashkar-Kyshtak_Osh" },
  { label: "Майлуу-Суу, Жалал-Абад", value: "Mayluu-Suu_Jalal-Abad" },
  { label: "Военно-Антоновка, Чуй", value: "Voyenno-Antonovka_Chüy" },
  { label: "Массы, Жалал-Абад", value: "Massy_Jalal-Abad" },
  { label: "Таш-Комур, Жалал-Абад", value: "Tash-Komür_Jalal-Abad" },
  { label: "Кара-Кол, Жалал-Абад", value: "Kara-Kol_Jalal-Abad" },
  { label: "Новопавловка, Чуй", value: "Novopavlovka_Chüy" },
  { label: "Исфана, Баткен", value: "Isfana_Batken" },
  { label: "Эски-Ноокат, Ош", value: "Eski-Nookat_Osh" },
  { label: "Ивановкаы, Чуй", value: "Ivanovka_Chüy" },
  { label: "Мырза-Аке, Ош", value: "Myrza-Ake_Osh" },
  { label: "Кара-Кулжа, Ош", value: "Kara-Kulja_Osh" },
  { label: "Ат-Башы, Нарын", value: "At-Bashy_Naryn" },
  { label: "Ала-Бука, Жалал-Абад", value: "Ala-Buka_Jalal-Abad" },
  { label: "Кызыл-Суу, Ыссык-Кол", value: "Kyzyl-Suu_Ysyk-Kol" },
  { label: "Кок-Жангак, Жалал-Абад", value: "Kok-Janggak_Jalal-Abad" },
  { label: "Кочкор-Ата, Жалал-Абад", value: "Kochkor-Ata_Jalal-Abad" },
  { label: "Уч-Коргон, Баткен", value: "Uch-Korgon_Batken" },
  { label: "Александровка, Чуй", value: "Aleksandrovka_Chüy" },
  { label: "Боконбаев, Ыссык-Кол", value: "Bokonbaev_Ysyk-Kol" },
  { label: "Кербен, Жалал-Абад", value: "Kerben_Jalal-Abad" },
  { label: "Фрунзе, Баткен", value: "Frunze_Batken" },
  { label: "Теплоключенка, Ыссык-Кол", value: "Teploklyuchenka_Ysyk-Kol" },
  { label: "Гулчо, Ош", value: "Gülcho_Osh" },
  { label: "Кызыл-Адыр, Талас", value: "Kyzyl-Adyr_Talas" },
  { label: "Дыйкан-Kyshtak, Ош", value: "Dyykan-Kyshtak_Osh" },
  { label: "Сулукту, Баткен", value: "Sülüktü_Batken" },
  { label: "Советское, Жалал-Абад", value: "Sovetskoe_Jalal-Abad" },
  { label: "Чуй, Чуй", value: "Chüy_Chüy" },
  { label: "Туп, Ыссык-Кол", value: "Tüp_Ysyk-Kol" },
  { label: "Шамалды-Сай, Жалал-Абад", value: "Shamaldy-Say_Jalal-Abad" },
  { label: "Нариман, Ош", value: "Nariman_Osh" },
  { label: "Кызыл-Кыштак, Ош", value: "Kyzyl-Kyshtak_Osh" },
  { label: "Аламедин, Чуй", value: "Alamedin_Chüy" },
  { label: "Жаны-Кыгтак, Ош", value: "Jangy-Kyshtak_Osh" },
  { label: "Чолпон-Ата, Ыссык-Кол", value: "Cholpon-Ata_Ysyk-Kol" },
  { label: "Бек-Абад, Жалал-Абад", value: "Bek-Abad_Jalal-Abad" },
  { label: "Бужум, Баткен", value: "Buzhum_Batken" },
  { label: "Кочкор, Нарын", value: "Kochkor_Naryn" },
  { label: "Нарын, Жалал-Абад", value: "Naryn_Jalal-Abad" },
  { label: "Арсланбаб, Жалал-Абад", value: "Arslanbob_Jalal-Abad" },
  { label: "Казарман, Жалал-Абад", value: "Kazarman_Jalal-Abad" },
  { label: "Петровка, Чуй", value: "Petrovka_Chüy" },
  { label: "Кара-Таш, Ош", value: "Kara-Tash_Osh" },
  { label: "Гулбаар, Ош", value: "Gülbaar_Osh" },
  { label: "Баетов, Нарын", value: "Baetov_Naryn" },
  { label: "Ленинское, Чуй", value: "Leninskoe_Chüy" },
  { label: "Садовое, Чуй", value: "Sadovoye_Chüy" },
  { label: "Маевка, Чуй", value: "Maevka_Chüy" },
  { label: "Кемин, Чуй", value: "Kemin_Chüy" },
  { label: "Айдеркен, Баткен", value: "Aydarken_Batken" },
  { label: "Чон-Арык, Бишкек", value: "Chong-Aryk_Bishkek" },
  { label: "Кулунду, Баткен", value: "Kulundu_Batken" },
  { label: "Кайынды, Чуй", value: "Kayyngdy_Chüy" },
  { label: "Тепе-Коргон, Ош", value: "Tepe-Korgon_Osh" },
  { label: "Кара-Бак, Баткен", value: "Kara-Bak_Batken" },
  { label: "Ладан Кара, Жалал-Абад", value: "Ladan Kara_Jalal-Abad" },
  { label: "Шопоков, Чуй", value: "Shopokov_Chüy" },
  { label: "Покровка, Талас", value: "Pokrovka_Talas" },
  { label: "Таш-Булак, Жалал-Абад", value: "Tash-Bulak_Jalal-Abad" },
  { label: "Саруу, Ыссык-Кол", value: "Saruu_Ysyk-Kol" },
  { label: "Ак-Коргон, Жалал-Абад", value: "Ak-Korgon_Jalal-Abad" },
  { label: "Самаркандек, Баткен", value: "Samarkandyk_Batken" },
  { label: "Андарак, Баткен", value: "Andarak_Batken" },
  { label: "Новониколаевка, Чуй", value: "Novonikolayevka_Chüy" },
  { label: "Марказ, Баткен", value: "Markaz_Batken" },
  { label: "Октябрьская, Чуй", value: "Oktyabr’skoe_Chüy" },
  { label: "Красная Речка, Чуй", value: "Krasnaya Rechka_Chüy" },
  { label: "Кара-Дехан, Ош", value: "Kara-Dekhkan_Osh" },
  { label: "Кызыл-Бел, Баткен", value: "Kyzyl-Bel’_Batken" },
  { label: "Бостери, Ыссык-Кол", value: "Bosteri_Ysyk-Kol" },
  { label: "Пригородное, Чуй", value: "Prigorodnoye_Chüy" },
  { label: "Шымгент, Талас", value: "Chymgent_Talas" },
  { label: "Катран, Баткен", value: "Katran_Batken" },
  { label: "Кызыл-Жар, Жалал-Абад", value: "Kyzyl-Dzhar_Jalal-Abad" },
  { label: "Мырза-Арык, Ош", value: "Myrza-Aryk_Osh" },
  { label: "Чаек, Нарын", value: "Chaek_Naryn" },
  { label: "Борбаш, Ош", value: "Borbash_Osh" },
  { label: "Иваново-Алексеевка, Талас", value: "Ivanovo-Alekseyevka_Talas" },
  { label: "Центральное, Баткен", value: "Tsentralnoye_Batken" },
  { label: "Жаны-Арык, Ош", value: "Jangy-Aryk_Osh" },
  { label: "Кен-Сай, Ош", value: "Ken-Say_Osh" },
  { label: "Баястан, Жалал-Абад", value: "Bayastan_Jalal-Abad" },
  { label: "Отуз-Адыр, Ош", value: "Otuz-Adyr_Osh" },
  { label: "Арал, Жалал-Абад", value: "Aral_Jalal-Abad" },
  { label: "Кыргыз-Ата, Ош", value: "Kyrgyz-Ata_Osh" },
  { label: "Михайловка, Жалал-Абад", value: "Mikhaylovka_Jalal-Abad" },
  { label: "Кумуш-Азиз, Жалал-Абад", value: "Kümüsh-Aziz_Jalal-Abad" },
  { label: "Васильевка, Чуй", value: "Vasil’evka_Chüy" },
  { label: "Кочубаев, Ош", value: "Kochübaev_Osh" },
  { label: "Кызыл-Жол, Баткен", value: "Kyzyl-Jol_Batken" },
  { label: "Халмион, Баткен", value: "Khalmion_Batken" },
  { label: "Жар-Кыштак, Жалал-Абад", value: "Dzhar-Kyshtak_Jalal-Abad" },
  { label: "Кыргыз-Чек, Ош", value: "Kyrgyz-Chek_Osh" },
  { label: "Правда, Ош", value: "Pravda_Osh" },
  { label: "Жаны-Жер, Чуй", value: "Jangy-Jer_Chüy" },
  { label: "Гулстан, Ош", value: "Gülstan_Osh" },
  { label: "Кызыл-Шарк, Ош", value: "Kyzyl-Shark_Osh" },
  { label: "Баш-Булак, Ош", value: "Bash-Bulak_Osh" },
  { label: "Сосновка, Чуй", value: "Sosnovka_Chüy" },
  { label: "Айра-Там, Жалал-Абад", value: "Ayra-Tam_Jalal-Abad" },
  { label: "Жаны-Арык, Жалал-Абад", value: "Dzhany-Aryk_Jalal-Abad" },
  { label: "Благовешенка, Жалал-Абад", value: "Blagoveshenka_Jalal-Abad" },
  { label: "Кызыл-Ай, Жалал-Абад", value: "Kyzyl-Ay_Jalal-Abad" },
  { label: "Кыргыз-Кыштак, Баткен", value: "Kyrgyz-Kyshtak_Batken" },
  { label: "Шоро-Башат, Ош", value: "Shoro-Bashat_Osh" },
  { label: "Чарбак, Жалал-Абад", value: "Charbak_Jalal-Abad" },
  { label: "Толойкон, Ош", value: "Tëlëyken_Osh" },
  { label: "Ключевка, Талас", value: "Klyuchevka_Talas" },
  { label: "Дюксембург, Чуй", value: "Dyuksemburg_Chüy" },
  { label: "Жерге-Тал, Жалал-Абад", value: "Jerge-Tal_Jalal-Abad" },
  { label: "Жийдалик, Ош", value: "Dzhiydalik_Osh" },
  { label: "Таш-Добо, Чуй", value: "Tash-Dobo_Chüy" },
  { label: "Бешик-Жон, Жалал-Абад", value: "Beshik-Jon_Jalal-Abad" },
  { label: "Милянфан, Чуй", value: "Milyanfan_Chüy" },
  { label: "Борбаш, Ош", value: "Borbash_Osh" },
  { label: "Комсомол, Жалал-Абад", value: "Komsomol_Jalal-Abad" },
  { label: "Сары-Могол, Ош", value: "Sary-Mogol_Osh" },
  { label: "Шералы, Ош", value: "Sheraly_Osh" },
  { label: "Ача-Кайынды, Нарын", value: "Acha-Kayyngdy_Naryn" },
  { label: "Ак-Таш, Ош", value: "Ak-Tash_Osh" },
  { label: "Григорьевка, Ыссык-Кол", value: "Grigor’evka_Ysyk-Kol" },
  { label: "Кызыл-Сенгир, Ош", value: "Kyzyl-Sengir_Osh" },
  { label: "Чек, Баткен", value: "Chek_Batken" },
  { label: "Нойгут, Ош", value: "Noygut_Osh" },
  { label: "Монок, Ош", value: "Monok_Osh" },
  { label: "Чаныр-Таш, Жалал-Абад", value: "Changyr-Tash_Jalal-Abad" },
  { label: "Каныш-Кыйа, Жалал-Абад", value: "Kanysh-Kyya_Jalal-Abad" },
  { label: "Кызыл-Too, Ош", value: "Kyzyl-Too_Osh" },
  { label: "Учар, Ош", value: "Uchar_Osh" },
  { label: "Маданият, Ош", value: "Madaniyat_Osh" },
  { label: "Тош, Жалал-Абад", value: "Tësh_Jalal-Abad" },
  { label: "Жыланды, Ош", value: "Jylandy_Osh" },
  { label: "Коминтерн, Жалал-Абад", value: "Komintern_Jalal-Abad" },
  { label: "Бешкент, Баткен", value: "Beshkent_Batken" },
  { label: "Качкынчы, Жалал-Абад", value: "Kachkynchy_Jalal-Abad" },
  { label: "Алга, Баткен", value: "Alga_Batken" },
  { label: "Ак-Терек, Ыссык-Кол", value: "Ak-Terek_Ysyk-Kol" },
  { label: "Кажы-Сай, Ыссык-Кол", value: "Kaji-Say_Ysyk-Kol" },
  { label: "Орто-Сай, Бишкек", value: "Orto-Say_Bishkek" },
  { label: "Баш-Кайынды, Нарын", value: "Bash-Kayyngdy_Naryn" },
  { label: "Савай, Ош", value: "Savay_Osh" },
  { label: "Юрьевка, Чуй", value: "Yur’evka_Chüy" },
  { label: "Шамал-Терек, Ош", value: "Shamal-Terek_Osh" },
  { label: "Беш-Кюнгей, Чуй", value: "Besh-Kyungëy_Chüy" },
  { label: "Меркит, Ош", value: "Merkit_Osh" },
  { label: "Арашан, Чуй", value: "Arashan_Chüy" },
  { label: "Жаны-Акман, Жалал-Абад", value: "Dzhany-Akman_Jalal-Abad" },
  { label: "Кен-Булук, Чуй", value: "Ken-Buluk_Chüy" },
  { label: "Уч-Терек, Жалал-Абад", value: "Üch-Terek_Jalal-Abad" },
  { label: "Ак-Суу, Баткен", value: "Ak-Suu_Batken" },
  { label: "Кызыл-Сенир, Жалал-Абад", value: "Kyzyl-Senir_Jalal-Abad" },
  { label: "Дмитриевка, Чуй", value: "Dmitriyevka_Chüy" },
  { label: "Таран-Базар, Жалал-Абад", value: "Taran-Bazar_Jalal-Abad" },
  { label: "Орозбеков, Баткен", value: "Orozbekov_Batken" },
  { label: "Мамажан, Ош", value: "Mamazhan_Osh" },
  { label: "Чат-Кол, Чуй", value: "Chat-Kël’_Chüy" },
  { label: "Курмонту, Ыссык-Кол", value: "Kürmontü_Ysyk-Kol" },
  { label: "Интернационал, Ош", value: "Internatsional_Osh" },
  { label: "Ак-Жол, Чуй", value: "Ak-Dzhol_Chüy" },
  { label: "Эмгекчил, Нарын", value: "Emgekchil_Naryn" },
  { label: "Герей-Шорон, Ош", value: "Gerey-Shoron_Osh" },
  { label: "Карамык, Ош", value: "Karamyk_Osh" },
  { label: "Тотия, Жалал-Абад", value: "Totiya_Jalal-Abad" },
  { label: "Оогон-Талаа, Жалал-Абад", value: "Oogon-Talaa_Jalal-Abad" },
  { label: "Орто-Алыш, Чуй", value: "Orto-Alysh_Chüy" },
  { label: "Баш-Кууганды, Нарын", value: "Bash-Kuugandy_Naryn" },
  { label: "Камышовка, Чуй", value: "Kamyshanovka_Chüy" },
  { label: "Орто-Азия, Жалал-Абад", value: "Orto-Aziya_Jalal-Abad" },
  { label: "Алма, Жалал-Абад", value: "Alma_Jalal-Abad" },
  { label: "Кара-Жыгач, Баткен", value: "Kara-Dzhygach_Batken" },
  { label: "Ак-Шор, Ош", value: "Ak-Shor_Osh" },
  { label: "Романовка, Чуй", value: "Romanovka_Chüy" },
  { label: "Кегети, Чуй", value: "Kegeti_Chüy" },
  { label: "Учкун, Нарын", value: "Uchkun_Naryn" },
  { label: "Селекционное, Чуй", value: "Selektsionnoye_Chüy" },
  { label: "Сары-Кашка, Жалал-Абад", value: "Sary-Kashka_Jalal-Abad" },
  { label: "Багыш, Жалал-Абад", value: "Bagysh_Jalal-Abad" },
  { label: "Саргата, Жалал-Абад", value: "Sargata_Jalal-Abad" },
  { label: "Кенеш, Чуй", value: "Kenesh_Chüy" },
  { label: "Кара-Алма, Жалал-Абад", value: "Kara-Alma_Jalal-Abad" },
  { label: "Мады, Ош", value: "Mady_Osh" },
  { label: "Балбай, Ыссык-Кол", value: "Balbay_Ysyk-Kol" },
  { label: "Жар-Башы, Ош", value: "Jar-Bashy_Osh" },
  { label: "Кызыл-Туу, Жалал-Абад", value: "Kyzyl-Tuu_Jalal-Abad" },
  { label: "Кенжекул, Ош", value: "Kenzhekul_Osh" },
  { label: "Кенеш, Ош", value: "Kengesh_Osh" },
  { label: "Кой-Таш, Чуй", value: "Koy-Tash_Chüy" },
  { label: "Жаны-Дыйкан, Жалал-Абад", value: "Dzhany-Dyykan_Jalal-Abad" },
  { label: "Бостон, Жалал-Абад", value: "Boston_Jalal-Abad" },
  { label: "Ачык-Суу, Ош", value: "Achyk-Suu_Osh" },
  { label: "Сай, Ош", value: "Say_Osh" },
  { label: "Алим-Teпe, Ош", value: "Alim-Tepe_Osh" },
  { label: "Колот, Жалал-Абад", value: "Kolot_Jalal-Abad" },
  { label: "Ак-Тектир, Жалал-Абад", value: "Ak-Tektir_Jalal-Abad" },
  { label: "Прогресс, Чуй", value: "Progress_Chüy" },
  { label: "Тогуз-Булак, Ош", value: "Toguz-Bulak_Osh" },
  { label: "Кара-Ой, Талас", value: "Kara-Oy_Talas" },
  { label: "Кайрагач, Баткен", value: "Kayragach_Batken" },
  { label: "Арал, Жалал-Абад", value: "Aral_Jalal-Abad" },
  { label: "Маловодное, Чуй", value: "Malovodnoye_Chüy" },
  { label: "Масадан, Жалал-Абад", value: "Masadan_Jalal-Abad" },
  { label: "Эркин-Too, Ош", value: "Erkin-Too_Osh" },
  { label: "Кызыл-Булак, Баткен", value: "Kyzyl-Bulak_Batken" },
  { label: "Коргон, Баткен", value: "Korgon_Batken" },
  { label: "Таян, Баткен", value: "Tayan_Batken" },
  { label: "Тогуз-Булак, Баткен", value: "Toguz-Bulak_Batken" },
  { label: "Ак-Татыр, Баткен", value: "Ak-Tatyr_Batken" },
  { label: "Ак-Сай, Баткен", value: "Ak-Say_Batken" },
  { label: "Ормош, Баткен", value: "Ormosh_Batken" },
  { label: "Кара-Добо, Баткен", value: "Kara-Dobo_Batken" },
  { label: "Ленинполь, Талас", value: "Leninpol’_Talas" },
  { label: "Боз-Адыр, Баткен", value: "Boz-Adyr_Batken" },
];

kgData.sort((a, b) => a.label.localeCompare(b.label));
